import { ChangeEvent, useCallback, useMemo, CSSProperties } from 'react';
import {
  FormContextType,
  getTemplate,
  Registry,
  RJSFSchema,
  StrictRJSFSchema,
  TranslatableString,
  UIOptionsType,
  WidgetProps,
} from '@rjsf/utils';
import Markdown from 'markdown-to-jsx';
import { CoreAPI } from '../../Flows/CoreAPI';
import React from 'react';

type FileInfoType = {
  id: number;
  url?: string | null;
  name: string;
  size: number;
  type: string;
};

function addDataToURL(url: string, data: any): string {
  if (url === null) {
    return '';
  }

  return `${url}?id=${data.id}&name=${data.name}&size=${data.size}&type=${data.type}`;
}

function createAttachment(formContext: any, file: File, name: string, resolve: any) {
    const formData = new FormData();
    const api = CoreAPI(formContext.authToken);

    formData.append('settings_attachment[file]', file, name);
    formData.append('settings_attachment[resourceable_id]', formContext.resourceId);
    formData.append('settings_attachment[resourceable_type]', formContext.resourceClass);
    formData.append('settings_attachment[create_token]', formContext.createToken);

    api.settingsAttachments(formData, formContext.csrfToken).then((response: any) => {
      resolve(response);
    });
}

function updateAttachment(formContext: any, file: File, name: string, resolve: any) {
  const formData = new FormData();
  const api = CoreAPI(formContext.authToken);

  formData.append('settings_attachment[new_file]', file, name);
  formData.append('settings_attachment[update_token]', formContext.updateToken);

  api.updateSettingsAttachment(formContext.attachmentId, formData, formContext.csrfToken).then((response: any) => {
    if(response.id) {
      resolve(response);
    } else {
      createAttachment(formContext, file, name, resolve);
    }
  });
}

function uploadAttachment(formContext: any, file: File, name: string) {
  return new Promise((resolve, reject) => {
    if(formContext.attachmentId) {
      updateAttachment(formContext, file, name, resolve);
    } else {
      createAttachment(formContext, file, name, resolve);
    }
  });
}

function updateDestroyToken(formContext: any, url: string) {
  return new Promise((resolve, _) => {
    const api = CoreAPI(formContext.authToken);
    const data = extractFileInfo([url])[0];
    const formData = new FormData();

    formData.append('settings_attachment[destroy_token]', formContext.destroyToken);
    api.updateSettingsAttachment(data.id, formData, formContext.csrfToken).then((response: any) => {
      resolve(response);
    });
  });
}

function processFile(formContext: any, file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, _) => {
    uploadAttachment(formContext, file, name).then((result: any) => {
      resolve({
        id: result.id,
        url: addDataToURL(result.file.url, { id: result.id, name, size, type }),
        name,
        size,
        type,
      });
    });
  });
}

function processFiles(files: FileList, formContext: any) {
  return Promise.all(Array.from(files).map((file) => processFile(formContext, file)));
}

function extractFileInfo(urls: string[]): FileInfoType[] {
  if (!urls) {
    return [] as FileInfoType[];
  }

  return urls.reduce((arr: any, url: string) => {
    if (!url) {
      return arr;
    }
    try {
      const queryString = new URL(url).search;
      const urlParams = new URLSearchParams(queryString);

      return [
        ...arr,
        {
          id: Number(urlParams.get('id')),
          url: url,
          name: urlParams.get('name') || '',
          size: Number(urlParams.get('size')),
          type: urlParams.get('type') || '',
        }];
    } catch (e) {
      return arr;
    }
  }, [] as FileInfoType[]);
}

function FilesInfo<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  filesInfo,
  registry,
  onRemove,
  options,
}: {
  filesInfo: FileInfoType[];
  registry: Registry<T, S, F>;
  onRemove: (url: string, index: number) => void;
  options: UIOptionsType<T, S, F>;
}) {
  if (filesInfo.length === 0) {
    return null;
  }
  const { translateString } = registry;

  const { RemoveButton } = registry.templates.ButtonTemplates;

  const btnStyle: CSSProperties = {
    paddingLeft: '6px',
    paddingRight: '6px',
    fontWeight: 'bold',
  };

  return (
    <ul className='file-info'>
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type, url } = fileInfo;
        const handleRemove = (e: any) => {
          e.preventDefault();
          return onRemove(url || '?id=0', key);
        };

        return (
          <li key={key}>
            <a href={url || undefined} download className='flex justify-between mt-2'>
              <Markdown className='mr-2 flex flex-col'>
                {translateString(TranslatableString.FilesInfo, [name, type, String(size)])}
              </Markdown>
              <RemoveButton
                style={btnStyle}
                onClick={handleRemove}
                className={'core-button core-button-secondary core-button-medium'}
                registry={registry} />
            </a>
          </li>
        );
      })}
    </ul>
  );
}

function FileUrlWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: WidgetProps<T, S, F>,
) {
  const { disabled, readonly, required, multiple, onChange, value, options, registry } = props;
  const inputClass = 'border rounded-lg p-2 focus:border-primary focus:outline-none w-full bg-background border-muted-foreground';

  const handleNewFiles = (files: FileList) => {
    processFiles(files, props.formContext).then((filesInfoParams) => {
      const newValue = filesInfoParams.map((fileInfo) => fileInfo.url);

      if (multiple) {
        let urls = Array.isArray(value) ? value : [value];
        onChange(urls.concat(newValue));
      } else {
        onChange(newValue[0]);
      }
    });
  };

  const handleOldAttachment = (url: string, files: FileList) => {
    const data = extractFileInfo([url])[0];
    const formContext = props.formContext as Object;

    formContext['attachmentId'] = data.id;
    processFile(formContext, files[0]).then((fileInfo) => {
      onChange(fileInfo.url);
    })
  }

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files) return;

      if(value && !multiple) {
        handleOldAttachment(value, files);
      } else {
        handleNewFiles(files);
      }
    },
    [multiple, value, onChange],
  );

  const filesInfo = useMemo(() => extractFileInfo(Array.isArray(value) ? value : [value]), [value]);

  const rmFile = useCallback(
    (url: string, index: number) => updateDestroyToken(props.formContext, url).then((_: any) => {
      if (multiple) {
        const urls = Array.isArray(value) ? value : [value];
        const newValue = urls.filter((_: any, i: number) => i !== index);
        onChange(newValue);
      } else {
        onChange(undefined);
      }
    }),
    [multiple, value, onChange],
  );

  return (
    <div>
      <input
        id={props.id}
        type='file'
        className={inputClass}
        disabled={disabled || readonly}
        required={value ? false : required}
        onChange={handleChange}
        value=''
        accept={options.accept ? String(options.accept) : undefined}
        multiple={multiple}
      />
      <FilesInfo<T, S, F>
        filesInfo={filesInfo}
        onRemove={rmFile}
        registry={registry}
        options={options}
      />
    </div>
  );
}

export default FileUrlWidget;
