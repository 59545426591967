import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "button"];

  setLoading(e) {
    const app = e.target.closest(".tab-disabled").getAttribute("data-app");
    this.buttonTargets.forEach((button) => {
      if (button.querySelector(`.loopos-tab-container--${app}`)) {
        button
          .querySelector(`.loopos-tab-container--${app}`)
          .classList.add(`loopos-tab-container--default-${app}`);
        button
          .querySelector(`.loopos-tab-container--${app}`)
          .classList.remove(`loopos-tab-container--${app}`);
      }
    });
    e.target
      .closest(".tab-disabled")
      .classList.add(`loopos-tab-container--${app}`);
    this.containerTarget.innerHTML = "";
    this.containerTarget.classList = "tabs__loading";
  }
}
