import React, { FC } from "react";
import { TagsDropdown, Tag } from "@theloopco/loopos-ds-sdk";

type TagsDropdownProps = typeof TagsDropdown extends FC<infer P> ? P : never;

const LoopOsTagsDropdown: FC<TagsDropdownProps> = ({
    headerTags,
    searchDataAttributes,
    searchValue,
    handleCreate,
    handleSelectOption,
    options,
    numberShownOptions,
    showSearchBar,
    canDelete,
}) => {
    return (
        <TagsDropdown
            headerTags={headerTags}
            searchDataAttributes={searchDataAttributes}
            searchValue={searchValue}
            handleCreate={handleCreate}
            handleSelectOption={handleSelectOption}
            options={options}
            numberShownOptions={numberShownOptions}
            showSearchBar={showSearchBar}
            canDelete={canDelete}
        />
    );
};

export default LoopOsTagsDropdown;