import React, { FC } from "react";
import { Toggle } from "@theloopco/loopos-ds-sdk";

type TagProps = typeof Toggle extends FC<infer P> ? P : never;

const LoopOsToggle: FC<TagProps> = ({
  name,
  small,
  id,
  dataAttributes,
  inputDataAttributes,
  checked,
  blocked,
}) => {
  return (
    <Toggle
      checked={checked}
      name={name}
      small={small}
      id={id}
      dataAttributes={dataAttributes}
      inputDataAttributes={inputDataAttributes}
      blocked={blocked}
    />
  );
};

export default LoopOsToggle;
