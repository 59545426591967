import React, { FC } from "react";
import { TagList, Tag } from "@theloopco/loopos-ds-sdk";

type TagListProps = typeof TagList extends FC<infer P> ? P : never;

const LoopOsTagList: FC<TagListProps> = ({
  catList,
  maxDisplayed,
  tagList,
  tSize,
  tooltip,
  tagsToAdd,
  dropdownDisplay,
  searchBarDropdownDisplay,
}) => {
  return (
    <div>
      <TagList
        tSize={tSize}
        catList={catList.map((element) => (
          <div className="tooltip-parent-wrapper">
            <Tag
              kind={element.kind}
              text={element.title}
              tSize={tSize}
              icon={element.icon}
              line={element.line}
              configurable={element.configurable}
              deleteIcon={element.deleteIcon}
              deleteDataAttributes={element.deleteDataAttributes}
            />
            {tooltip && (
              <div className="tooltip tooltip--top">
                <p>{tooltip}</p>
              </div>
            )}
            {!tooltip && element.tooltip && (
              <div className="tooltip tooltip--top">
                <p>{element.tooltip}</p>
              </div>
            )}
          </div>
        ))}
        maxDisplayed={maxDisplayed}
        tagsToAdd={tagsToAdd}
        dropdownDisplay={dropdownDisplay}
        searchBarDropdownDisplay={searchBarDropdownDisplay}
        tagList={tagList.map((element, i) =>
          i < maxDisplayed ? (
            <div className="tooltip-parent-wrapper" key={i}>
              <Tag
                kind={element.kind}
                text={element.title}
                tSize={tSize}
                icon={element.icon}
                line={element.line}
                configurable={element.configurable}
                deleteIcon={element.deleteIcon}
                deleteDataAttributes={element.deleteDataAttributes}
              />
              {tooltip && (
                <div className="tooltip tooltip--top">
                  <p>{tooltip}</p>
                </div>
              )}
              {!tooltip && element.tooltip && (
                <div className="tooltip tooltip--top">
                  <p>{element.tooltip}</p>
                </div>
              )}
            </div>
          ) : (
            <Tag
              kind={element.kind}
              text={element.title}
              tSize={tSize}
              icon={element.icon}
              line={element.line}
              configurable={element.configurable}
              deleteIcon={element.deleteIcon}
              deleteDataAttributes={element.deleteDataAttributes}
            />
          )
        )}
      />
    </div>
  );
};

export default LoopOsTagList;
