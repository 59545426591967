import React, { FC } from "react";
import { ProtocolImageUploader } from "@theloopco/loopos-ds-sdk";

type TagProps = typeof ProtocolImageUploader extends FC<infer P> ? P : never;

const LoopOsImage: FC<TagProps> = ({ images, editable, rounded, size }) => {
  return (
    <ProtocolImageUploader
      images={images}
      editable={editable}
      rounded={rounded}
      size={size}
    />
  );
};

export default LoopOsImage;
