import React, { FC, useEffect } from "react";
import { Tab } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof Tab extends FC<infer P> ? P : never;

const MyComponent: FC<MyComponentProps> = ({
  text,
  isActive,
  icon,
  id,
  variant,
  role,
  dataAttributes,
  ariaAttributes,
}) => {
  return (
    <Tab
      text={text}
      isActive={isActive}
      icon={icon}
      iconRails
      variant={variant}
      id={id}
      role={role}
      dataAttributes={dataAttributes}
      ariaAttributes={ariaAttributes}
    />
  );
};

export default MyComponent;
