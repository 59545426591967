import React, { FC } from "react";
import { Breadcrumbs } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof Breadcrumbs extends FC<infer P> ? P : never;

const LoopOsCrumbs: FC<MyComponentProps> = ({ crumbs }) => {
  return <Breadcrumbs crumbs={crumbs} iconRails />;
};

export default LoopOsCrumbs;
