import React, { useState } from 'react';

const JsonFormToggler: any = (
  {
    isChecked,
    handleToggleChange,
  }) => {
  return (
    <div className='container-toggle justify-end mb-2'>
      <label className='switch'>
        <input
          type='checkbox'
          name='toggleForm'
          id='toggleForm'
          checked={isChecked}
          onChange={handleToggleChange}
        />
        <span className='slider round'></span>
      </label>
      <span className='ml-2 text-sm'>Toggle JSON Form</span>
    </div>
  );
};

export default JsonFormToggler;
