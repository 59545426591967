import React, { FC } from 'react'
import { ApproveTag } from '@theloopco/loopos-ds-sdk'

type ApproveTagProps = typeof ApproveTag extends FC<infer P> ? P : never

type ApproveTagRails = {
  approveTagDSProps: ApproveTagProps
  turboEditName: string
  path: string
  form_id: string
}

const LoopOsApproveTag: FC<ApproveTagRails> = ({
  approveTagDSProps: { text, handleApproveClick, handleEditClick, kind, locale },
  turboEditName,
  path,
  form_id,
}) => {
  return (
    <ApproveTag
      text={text}
      handleApproveClick={() => {
        document.getElementById(form_id).submit()
      }}
      handleEditClick={() => {
        document.getElementById(turboEditName).src = path
      }}
      kind={kind}
      locale={locale}
    />
  )
}

export default LoopOsApproveTag
