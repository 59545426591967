import * as React from 'react';
import {
  FieldTemplateProps,
  FormContextType,
  getTemplate,
  getUiOptions,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';

export default function FieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({
    id,
    children,
    displayLabel,
    rawErrors = [],
    errors,
    help,
    description,
    rawDescription,
    classNames,
    style,
    disabled,
    label,
    hidden,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    required,
    schema,
    uiSchema,
    registry,
  }: FieldTemplateProps<T, S, F>) {
  const uiOptions = getUiOptions(uiSchema);
  const WrapIfAdditionalTemplate = getTemplate<
    'WrapIfAdditionalTemplate',
    T,
    S,
    F
  >('WrapIfAdditionalTemplate', registry, uiOptions);
  if (hidden) {
    return <div className='hidden'>{children}</div>;
  }

  const hasDescription = !!rawDescription && rawDescription.length > 0 
  const shownLabel = hasDescription ? rawDescription : label

  return (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      style={style}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    >
      <div className='mb-4 block'>
        {displayLabel && (
          <label
            htmlFor={id}
            className={`mb-2 inline-block ${
              rawErrors.length > 0 ? 'text-red-500' : ''
            }`}
          >
            {shownLabel}
            {required ? ' *' : null}
          </label>
        )}
        {children}
        {/* Hidden, the description is used, if it does not exist, label is used */}
        {false && displayLabel && rawDescription && (
          <small className='mt-1 block'>
            <div
              className={`${
                rawErrors.length > 0 ? 'text-red-500' : 'text-muted-foreground'
              }`}
            >
              {description}
            </div>
          </small>
        )}
        {errors}
        {help}
      </div>
    </WrapIfAdditionalTemplate>
  );
}
