import React, { FC } from "react";
import { CoreInput } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof CoreInput extends FC<infer P> ? P : never;

const Input: FC<MyComponentProps> = ({
  placeholder,
  id,
  value,
  type,
  size,
  name,
  readOnly,
}) => {
  return (
    <CoreInput
      id={id}
      placeholder={placeholder}
      type={type}
      size={size}
      name={name}
      readOnly={readOnly}
      value={value}
    />
  );
};

export default Input;
