import React, { FC } from 'react'
import { Tag } from '@theloopco/loopos-ds-sdk'

type TagProps = typeof Tag extends FC<infer P> ? P : never

const LoopOsTag: FC<TagProps> = ({
  text,
  kind,
  line,
  tSize,
  icon,
  configurable,
  deleteIcon,
  deleteDataAttributes,
  cursorType,
  dataAttributes,
  imgURL,
  backgroundColor,
  extraTag,
  assigningDropdown,
  tagList,
  tagsToAdd,
}) => {
  const attachedTag = {
    text,
    line,
    tSize,
    kind,
    icon,
  }
  if (typeof extraTag === 'object') {
    const buildAttachedTag = (extraTag: any) => {
      attachedTag.text = extraTag.text
      attachedTag.line = extraTag.line
      attachedTag.tSize = extraTag.tSize
      attachedTag.kind = extraTag.kind
      attachedTag.icon = extraTag.icon
    }
    buildAttachedTag(extraTag)
  }
  return (
    <Tag
      text={text}
      line={line}
      kind={kind}
      tSize={tSize}
      icon={icon}
      configurable={configurable}
      deleteIcon={deleteIcon}
      deleteDataAttributes={deleteDataAttributes}
      cursorType={cursorType}
      dataAttributes={dataAttributes}
      imgURL={imgURL}
      backgroundColor={backgroundColor}
      assigningDropdown={assigningDropdown}
      tagsToAdd={tagsToAdd}
      extraTag={
        extraTag === undefined ? undefined : (
          <Tag
            text={attachedTag.text}
            line={attachedTag.line}
            tSize={attachedTag.tSize}
            kind={attachedTag.kind}
            icon={attachedTag.icon}
          />
        )
      }
      tagList={tagList?.map((element, i) => (
        <div className="tooltip-parent-wrapper" key={i}>
          <Tag
            kind={element?.kind}
            text={element?.title}
            tSize={tSize}
            icon={element?.icon}
            line={element?.line}
            configurable={element?.configurable}
            deleteIcon={element?.deleteIcon}
            deleteDataAttributes={element?.deleteDataAttributes}
          />
        </div>
        ))
      }
    />
  )
}

export default LoopOsTag
