import React, { FC, useState } from 'react';
import { DatetimePicker, Input } from '@theloopco/loopos-ds-sdk';

type MyComponentProps = typeof DatetimePicker extends FC<infer P> ? P : never;

const LoopOsDatetimePicker: FC<MyComponentProps> = ({
  kind,
  lang,
  variant,
  isRange,
  granularity,
  placeholder,
}) => {
  const [firstCallback, setFirstCallback] = useState(true);

  const handleDateCallback = (date: any) => {
    if (date && !firstCallback) {
      localStorage.setItem('date', date);
      window.dispatchEvent(new Event('DateRangeEvent'));
    } else {
      setFirstCallback(false);
    }
  };

  return (
    <DatetimePicker
      railsIcon
      kind={kind}
      lang={lang}
      variant={variant}
      isRange={isRange}
      granularity={granularity}
      customInput={<Input className="datetime-picker-range" />}
      handleChange={(e: any) => handleDateCallback(e.value)}
      placeholder={placeholder}
    />
  );
};

export default LoopOsDatetimePicker;
