import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["drawer"];

  show(event) {
    this.hide(event)
    if (!this.drawerTarget.classList.contains("hidden")) return;

    let drawerContentId = event.currentTarget.dataset.targetModal
    let drawerContent = document.getElementById(drawerContentId)
    let clonedDrawerContent = drawerContent.cloneNode(true)

    clonedDrawerContent.classList.remove('hidden')

    this.drawerTarget.appendChild(clonedDrawerContent);
    this.drawerTarget.classList.add("flex");
    this.drawerTarget.classList.remove("hidden");

  }

  hide(event) {
    if (!this.drawerTarget.lastElementChild) return;

    this.drawerTarget.removeChild(this.drawerTarget.lastElementChild)
    this.drawerTarget.classList.remove("flex");
    this.drawerTarget.classList.add("hidden");
  }
}
