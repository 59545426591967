import React, { FC } from "react";
import { GeneralAlert } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof GeneralAlert extends FC<infer P> ? P : never;

const Input: FC<MyComponentProps> = ({
  title,
  variant,
  coloredText,
  text,
  icon,
  size,
  url,
}) => {
  return (
    <GeneralAlert
      title={title}
      variant={variant}
      coloredText={coloredText}
      text={text}
      icon={icon}
      size={size}
      url={url}
    />
  );
};

export default Input;
