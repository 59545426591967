// app/javascript/controllers/assign_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", () => {
      const elements = document.querySelectorAll(
        `[data-controller="${this.identifier}"]`
      );
      elements.forEach((element) => {
        element.classList.remove("loopui-assign-card--selected");
      });
      this.element.classList.add("loopui-assign-card--selected");
    });
  }
}
