import React, { FC, Fragment, useRef } from "react";
import { Select } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof Select extends FC<infer P> ? P : never;

const LoopOsSelect: FC<MyComponentProps> = ({
  options,
  icon,
  selectEventName,
  size,
  placeholder,
  defaultValue,
  app,
  menuPortalTarget,
  menuIsOpen,
}) => {
  const elementRef = useRef(null);

  const handleSelect = (value) => {
    if (selectEventName) {
      elementRef.current?.dispatchEvent(new CustomEvent(selectEventName, { detail: { value: value }, bubbles: true }))
    }
  }

  const portalTarget = menuPortalTarget ? document.querySelector(menuPortalTarget) : document.body;

  return (
    <div ref={elementRef}>
      <Select
        options={options}
        icon={icon}
        handleSelect={handleSelect}
        size={size}
        placeholder={placeholder}
        defaultValue={defaultValue}
        app={app}
        menuPortalTarget={portalTarget}
        menuIsOpen={menuIsOpen}
      />
    </div>
  );
};

export default LoopOsSelect;
