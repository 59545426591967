// app/javascript/controllers/modal_controller.js

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];

  connect(event) {}

  open(event) {
    event.preventDefault();

    this.modalTarget.showModal();
  }

  close(event) {
    event.preventDefault();

    this.modalTarget.dispatchEvent(new Event('modal-close'));
    this.modalTarget.close();
  }
}
