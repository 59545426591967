// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo-rails";
import "channels";
import "pages";
import "initializers";
import "flowbite";

//= require algoliasearch/lite
//= require instantsearch.js
//= require instantsearch.js/es/widgets

import * as looposUI from "../../../vendor/gems/loopos_ui/app/javascript/packs/loopos-ui.js";
import * as looposSettings from "../../../vendor/gems/loop-os-settings/app/javascript/packs/loopos-settings";
import * as LooposUniversalSearch from "../../../vendor/gems/loopos-universal-search/app/assets/javascript/packs/algolia_search.js";
const context1 = looposUI.componentRequireContext;
const context2 = require.context("components", true);

const combinedContext = looposUI.combineContexts(
  context1,
  context2,
  looposSettings.componentRequireContext
);
looposUI.ReactRailsUJS.useContext(combinedContext);
looposUI.mountComponents();

Rails.start();
ActiveStorage.start();
