import { Controller } from "stimulus";
import { destroy } from "@rails/request.js";
 
export default class extends Controller {
  static targets = ["delete", "modalbtn"];
  modalClickListener = null;
 
  setmodal(e) {
    const container = e.srcElement.closest(".tag-configurable-text_container");
    const dropdownContainer = e.srcElement.closest(
      ".core_dropdown-delete_container"
    );
    const url = container
      ? container.getAttribute("data-link")
      : dropdownContainer.getAttribute("data-link");
 
    if (this.modalClickListener) {
      this.modalbtnTarget.removeEventListener("click", this.modalClickListener);
    }
 
    this.modalClickListener = () => {
      this.submit(url);
    };
 
    this.modalbtnTarget.addEventListener("click", this.modalClickListener);
  }
 
  async submit(url) {
    await destroy(url, {
      responseKind: "turbo-stream",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    });
  }
}
