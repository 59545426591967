import React, { FC, useRef } from "react";
import { Pagination } from "@theloopco/loopos-ds-sdk";

type PaginationProps = typeof Pagination extends FC<infer P> ? P : never;

const LoopOsPagination: FC<PaginationProps> = ({
  totalItems,
  itemsRange,
  lang,
  displaySelect,
  onPagePrevious,
  onPageNext,
  onPagePerPage,
  app,
  onChangeSelect,
  options,
  defaultValue,
  isDisabledPagePrevious,
  isDisabledPageNext,
  dataAttributes,
}) => {
  const prevLinkRef = useRef(null);
  const nextLinkRef = useRef(null);
  const perPageLinkRef = useRef(null);

  const tempBackFunc = () => {
    if (prevLinkRef.current) {
      prevLinkRef.current.querySelector("a").click();
    }
  };

  const tempNextFunc = () => {
    if (nextLinkRef.current) {
      nextLinkRef.current.querySelector("a").click();
    }
  };

  const handleSelectChange = (selectedOption) => {
    const perPageLink = perPageLinkRef.current.querySelector("a")
    const perPageUrl = new URL(perPageLink.href)
    perPageUrl.searchParams.set('per_page', selectedOption.id)
    perPageLink.href = perPageUrl.href
    perPageLink.click()
  };

  const defaultPerPage = defaultValue ? parseInt(defaultValue, 10) : 10;

  const defaultOptions = [
    { id: 10, label: "10" },
    { id: 20, label: "20" },
    { id: 30, label: "30" },
    { id: 50, label: "50" },
  ];

  return (
    <>
      <div
        ref={prevLinkRef}
        dangerouslySetInnerHTML={{ __html: onPagePrevious }}
        className="hidden"
      />

      <div
        ref={nextLinkRef}
        dangerouslySetInnerHTML={{ __html: onPageNext }}
        className="hidden"
      />

      <div
        ref={perPageLinkRef}
        dangerouslySetInnerHTML={{ __html: onPagePerPage }}
        className="hidden"
      />

      <Pagination
        totalItems={totalItems}
        itemsRange={itemsRange}
        lang={lang}
        defaultValue={{
          id: defaultPerPage,
          label: defaultPerPage.toString(),
        }}
        displaySelect
        onPagePrevious={tempBackFunc}
        onPageNext={tempNextFunc}
        app={app}
        onChangeSelect={handleSelectChange}
        options={options || defaultOptions}
        iconRails
        isDisabledPagePrevious={isDisabledPagePrevious}
        isDisabledPageNext={isDisabledPageNext}
        dataAttributes={dataAttributes}
      />
    </>
  );
};

export default LoopOsPagination;
