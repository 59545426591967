import * as React from 'react';
import { BsPlus } from '@react-icons/all-files/bs/BsPlus';
import { FormContextType, IconButtonProps, RJSFSchema, StrictRJSFSchema, TranslatableString } from '@rjsf/utils';

export default function AddButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({uiSchema, registry, ...props}: IconButtonProps<T, S, F>) {
  const {translateString} = registry;
  return (
    <div className="fill-app-800-primary border-app-300 bg-app-300 loopos-button-new__layout loopos-button-new--large loopos-button-new loopos-button-new__row-reverse">
      <button
        {...props}
        style={{width: '100%'}}
        className={`text-app-800-primary ${props.className}`}
        title={translateString(TranslatableString.AddItemButton)}
      >
        <BsPlus />
      </button>
    </div>
  );
}
