import { Controller } from "@hotwired/stimulus";
import { Dropdown, initFlowbite } from "flowbite";
import $ from "jquery";

export default class extends Controller {
  static targets = ["back", "input", "badge", "submit"];

  state_stack = [];

  connect() {
    initFlowbite();
    this.adjustBadge();
    this.setIndeterminates();
  }

  onLabelClick(e) {
    let child = $(e.target).data("child");
    if (child) {
      $(`[data-filter-state="${child}"]`).removeClass("hidden");
      this.state_stack.push($(e.target).closest("ul").data("filterState"));
      $(e.target).closest("ul").addClass("hidden");
      this.resetBackButton();
    }
  }

  backButtonClick() {
    if (this.state_stack.length) {
      $(`[data-filter-state]`).addClass("hidden");
      let state = this.state_stack.pop();
      $(`[data-filter-state="${state}"]`).removeClass("hidden");
      this.resetBackButton();
    }
  }

  resetBackButton() {
    if (this.state_stack.length) {
      $(this.backTarget).removeClass("hidden");
    } else {
      $(this.backTarget).addClass("hidden");
    }
  }

  checkboxChanged(e) {
    let target = $(e.target);
    let child = target.data("child");
    let parent = target.data("parent");
    if (child) {
      if (target.is(":checked")) {
        $(`[data-filter-state="${child}"]`).find("input").prop("checked", true);
        target.siblings(".filter-next").hide();
      } else {
        $(`[data-filter-state="${child}"]`)
          .find("input")
          .prop("checked", false);
        target.siblings(".filter-next").show();
      }
    } else if (parent) {
      let count = $(`[data-filter-state="${parent}"]`).find("input").length;
      let countChecked = $(`[data-filter-state="${parent}"]`).find(
        "input:checked"
      ).length;
      if (target.is(":checked")) {
        if (count === countChecked) {
          $(`[data-child="${parent}"]`).prop("indeterminate", false);
          $(`[data-child="${parent}"]`).prop("checked", true);
        } else {
          $(`[data-child="${parent}"]`).prop("indeterminate", true);
        }
      } else {
        $(`[data-child="${parent}"]`).prop("checked", false);
        if (countChecked === 0)
          $(`[data-child="${parent}"]`).prop("indeterminate", false);
        else $(`[data-child="${parent}"]`).prop("indeterminate", true);
      }
    }
    this.adjustBadge();
  }

  adjustBadge() {
    $(this.badgeTargets).each(function () {
      let badge = $(this);
      let input = badge.siblings("input");
      let child = input.data("child");
      let count = 0;
      if (input.is(":checked")) {
        count = $(`[data-filter-state="${child}"]`).find("input").length;
      } else {
        count = $(`[data-filter-state="${child}"]`).find(
          "input:checked"
        ).length;
      }
      badge.html(count);
      if (count > 0) {
        badge.removeClass("hidden");
      } else {
        badge.addClass("hidden");
      }
    });
    this.adjustSubmitButton();
  }

  inputCheck(e) {
    if ($(e.target).find('input[type="checkbox"]').length > 0)
      $(e.target).find('input[type="checkbox"]').trigger("click");
    else {
      e.stopPropagation();
      $(e.target).prev('input[type="checkbox"]').trigger("click");
    }
  }

  setIndeterminates() {
    $(this.inputTargets).filter((index, elem) => {
      let parent = $(elem).data("parent");
      if (
        $(elem).is(":checked") &&
        !$(`[data-child="${parent}"]`).is(":checked")
      )
        $(`[data-child="${parent}"]`).prop("indeterminate", true);
    });
  }

  adjustSubmitButton() {
    let macro_states = $('[name="q[in_macro_state][]"]').filter(
      (index, elem) => {
        return $(elem).is(":checked");
      }
    ).length;
    let count = $(this.inputTargets).filter((index, elem) => {
      return $(elem).is(":checked");
    }).length;

    count -= macro_states;

    if (count > 0) {
      $(".loopui-filters-badge")
        .html(count)
        .addClass("inline-flex")
        .removeClass("hidden");
      $(this.submitTarget).val(`Apply (${count})`);
    } else {
      $(".loopui-filters-badge")
        .html(count)
        .addClass("hidden")
        .removeClass("inline-flex");
      $(this.submitTarget).val(`Apply`);
    }
  }
}
