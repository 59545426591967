import React, { FC } from 'react'
import { CoreInput } from '@theloopco/loopos-ds-sdk'

type CoreInputProps = typeof CoreInput extends FC<infer P> ? P : never

const previewStyle = {
  backgroundColor: '#f2f2f2',
  fontWeight: 200,
  fontSize: 'small',
  width: '100%',
  padding: '5px',
  borderRadius: '5px',
  marginTop: '5px',
  display: 'none',
}

const Input: FC<CoreInputProps> = ({ placeholder, id, value, type, size, name, readOnly, dataAttributes }) => {
  return (
    <>
      <CoreInput
        id={id}
        placeholder={placeholder}
        type={type}
        size={size}
        name={name}
        readOnly={readOnly}
        value={value}
        dataAttributes={dataAttributes}
      />
      {dataAttributes && dataAttributes['preview-id'] && (
        <div id={dataAttributes['preview-id']} style={previewStyle}></div>
      )}
    </>
  )
}

export default Input
