import { FormContextType, getSubmitButtonOptions, RJSFSchema, StrictRJSFSchema, SubmitButtonProps } from '@rjsf/utils';
import * as React from 'react';

export default function SubmitButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>(props: SubmitButtonProps<T, S, F>) {
  const {
    submitText,
    norender,
    props: submitButtonProps,
  } = getSubmitButtonOptions<T, S, F>(props.uiSchema);

  if (norender) {
    return null;
  }

  return (
    <div className="fill-app-800-primary border-app-300 bg-app-300 loopos-button-new__layout loopos-button-new--large loopos-button-new loopos-button-new__row-reverse">
      <button
        type='submit'
        className='text-app-800-primary'
        {...submitButtonProps}
      >
        {submitText}
      </button>
    </div>
  );
}
