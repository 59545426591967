import React, { FC } from "react";
import { ButtonNew } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof ButtonNew extends FC<infer P> ? P : never;

const LoopOsButton: FC<MyComponentProps> = ({
  app,
  text,
  icon,
  variant,
  size,
  handleClick,
  dataAttributes,
  iconPosition,
  kind,
  link,
  linkURL,
  disabled,
}) => {
  return (
    <ButtonNew
      disabled={disabled}
      app={app}
      text={text}
      icon={icon}
      iconPosition={iconPosition}
      handleClick={handleClick ? ()=> {eval(handleClick)} : undefined }
      variant={variant}
      size={size}
      dataAttributes={dataAttributes}
      kind={kind}
      link={link}
      linkURL={linkURL}
      railsIcon
    />
  );
};

export default LoopOsButton;
