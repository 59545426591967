import React, { FC } from "react";
import { IconCounter } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof IconCounter extends FC<infer P> ? P : never;

const LoopOsIconCounter: FC<MyComponentProps> = ({
  kind,
  iconRails,
  size,
  icon,
  counter,
  title,
  text,
  id,
}) => {
  return (
    <IconCounter
      kind={kind}
      iconRails={iconRails}
      icon={icon}
      size={size}
      counter={counter}
      title={title}
      text={text}
      id={id}
    />
  );
};

export default LoopOsIconCounter;
