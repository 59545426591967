import * as React from 'react';
import { ChangeEvent, FocusEvent } from 'react';
import {
  ariaDescribedByIds,
  enumOptionsIsSelected,
  enumOptionsValueForIndex,
  FormContextType,
  optionId,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils';

export default function RadioWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    onChange,
    onBlur,
    onFocus,
  }: WidgetProps<T, S, F>) {
  const {enumOptions, enumDisabled, emptyValue} = options;

  const _onChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) =>
    onChange(enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));
  const _onBlur = ({target: {value}}: FocusEvent<HTMLInputElement>) =>
    onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));
  const _onFocus = ({target: {value}}: FocusEvent<HTMLInputElement>) =>
    onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));

  const inline = Boolean(options && options.inline);

  return (
    <div className='mb-0'>
      {Array.isArray(enumOptions) &&
        enumOptions.map((option, index) => {
          const itemDisabled =
            Array.isArray(enumDisabled) &&
            enumDisabled.indexOf(option.value) !== -1;
          const checked = enumOptionsIsSelected<S>(option.value, value);

          const radio = (
            <label
              key={index}
              className={`block ${
                inline ? 'mr-3 inline-flex items-center' : ''
              }`}
            >
              <input
                id={optionId(id, index)}
                name={id}
                type='radio'
                disabled={disabled || itemDisabled || readonly}
                checked={checked}
                required={required}
                value={String(index)}
                onChange={_onChange}
                onBlur={_onBlur}
                onFocus={_onFocus}
                aria-describedby={ariaDescribedByIds<T>(id)}
                className='border-muted-foreground bg-background text-primary focus:ring-2 focus:ring-primary'
              />
              <span className='ml-2'>{option.label}</span>
            </label>
          );
          return radio;
        })}
    </div>
  );
}
