import React, { FC } from "react";
import { LogoApp } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof LogoApp extends FC<infer P> ? P : never;

const LoopOsLogoApp: FC<MyComponentProps> = ({
  app,
  size,
  icon,
  counter,
  title,
  tags,
  id,
}) => {
  return (
    <LogoApp
      app={app}
      icon={icon}
      size={size}
      counter={counter}
      title={title}
      tags={tags}
      id={id}
    />
  );
};

export default LoopOsLogoApp;
