import React, { FC } from "react";
import { Tooltip } from "@theloopco/loopos-ds-sdk";

type TagProps = typeof Tooltip extends FC<infer P> ? P : never;

const LoopOsTooltip: FC<TagProps> = ({
  id,
  title,
  text,
  link,
  newTab,
  darkMode,
  kind,
  actionBtnText,
  actionBtnFunc,
  secBtnText,
  secBtnFunc,
  children,
  tags,
  placement,
}) => {
  return (
    <Tooltip
      id={id}
      title={title}
      text={text}
      link={link}
      newTab={newTab}
      darkMode={darkMode}
      kind={kind}
      actionBtnText={actionBtnText}
      actionBtnFunc={actionBtnFunc}
      secBtnText={secBtnText}
      secBtnFunc={secBtnFunc}
      tags={tags}
      placement={placement}
    >
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </Tooltip>
  );
};

export default LoopOsTooltip;
