import * as looposUI from "../../../vendor/gems/loopos_ui/app/javascript/packs/loopos-ui.js";
import * as looposSettings from "../../../vendor/gems/loop-os-settings/app/javascript/packs/loopos-settings.js";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const context1 = looposUI.controllerRequireContext;
const context2 = require.context("../controllers", true, /_controller\.js$/);
const combinedContext = looposSettings.combineContexts(
  context1,
  context2,
  looposSettings.controllerRequireContext
);

looposSettings.application.load(definitionsFromContext(combinedContext));

const componentContext1 = looposUI.componentRequireContext;
const componentContext2 = require.context("components", true);
const combinedComponentContext = looposSettings.combineContexts(
  componentContext1,
  componentContext2,
  looposSettings.componentRequireContext
);
looposSettings.ReactRailsUJS.useContext(combinedComponentContext);
looposSettings.mountComponents();
