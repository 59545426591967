import React, { FC } from 'react'
import { ImpactCard } from '@theloopco/loopos-ds-sdk'

type Props = typeof ImpactCard extends FC<infer P> ? P : never

const LoopOsImpactCard: FC<Props> = ({ title, icon, iconRails, balanceValue, metricUnit, savedValue, reuseCost }) => {
  return (
    <ImpactCard
      title={title}
      icon={icon}
      iconRails={iconRails}
      balanceValue={balanceValue}
      metricUnit={metricUnit}
      savedValue={savedValue}
      reuseCost={reuseCost}
    />
  )
}

export default LoopOsImpactCard
